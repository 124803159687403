import React from "react"
import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { Link,useLocation,useHistory } from 'react-router-dom'
import { useContext } from "react"
import { UserContext } from "../../api"
import { auth } from "../../api/firebase-config"
import { signOut } from '@firebase/auth'

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
    return (
        <Link to={to}>
            <Text display="block" {...rest}>
                {children}
            </Text>
        </Link>
    )
}

const MenuLinks = ({ toggle, isOpen,setIsOpen }) => {
    
    const location = useLocation()
    const history = useHistory()
    const { isAuth,setIsAuth } = useContext(UserContext)

    const signOutUser = () => {
        signOut(auth)
        .then(()=>{
            localStorage.clear()
            setIsAuth(false)

            history.replace('/auth')
        })
    }
    let currentLocation = location.pathname
    const [currenPersistentLocation, setCurrentPersistentLocation] = React.useState(location.pathname)

    const handleExit = () => {
        if (currentLocation != currenPersistentLocation) {
            setIsOpen(false)
            setCurrentPersistentLocation(location.pathname)
        }
    }

    handleExit()
    return (
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
        >
            <Stack
                spacing={8}
                align="center"
                justify={["center", "space-around", "flex-end", "flex-end"]}
                direction={["row", "row", "row", "row"]}
                pt={[10, 4, 0, 0]}
                pb={[10, 4, 0, 0]}
                bgColor={{base:"purple.200",md:'transparent'}}
                pos={'relative'}
                top={0}
                zIndex={10}
            >
                {!isAuth && <>
                <MenuItem to="/pricing">Pricing</MenuItem>
                <MenuItem to="/auth">Sign in</MenuItem>
                </>}
                {isAuth && <>
                <MenuItem to="/current-plan">My Plan</MenuItem>
                <MenuItem to="/dashboard">Dashboard</MenuItem>
                <Button variant={'link'} color='#fffffe' _hover={{textDecoration:'none'}} _focus={{textDecoration:'none'}} _active={{color:'#fff'}} onClick={()=>signOutUser()}>Sign out</Button>
                </>}
                                
            </Stack>
        </Box>
    )
}

export default MenuLinks