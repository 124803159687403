import React,{useState} from 'react'
import { Box,Button,Text,IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,useDisclosure, Divider,
    FormControl,
    FormLabel,
    FormErrorMessage,Input,
    FormHelperText,Textarea,Flex,SimpleGrid,
    Switch,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { useParams,useLocation,useHistory } from 'react-router-dom';
import axios from 'axios'
import { BASEURL } from '../../../api';
import { FiTrash,FiThumbsUp,FiThumbsDown,FiCheck } from 'react-icons/fi'
import { auth } from '../../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
const EditCampaign = () => {
    const { campaignId } = useParams()
    const history = useHistory()
    const [user,setUser] = React.useState(null)
    const [emailTemplate,setEmailTemplate] = React.useState('')

    const [isCampaignDataFetched,setIsCampaignDataFetched] = useState(false)
    function TemplateVisualizationModal() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        return (
          <>
            {emailTemplate.length != 0 && <Button 
                variant={'link'}
                colorScheme='purple'
                onClick={onOpen}
                >Visualize</Button>}
      
            <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
              <ModalOverlay />
              <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
                <ModalHeader>E-mail Template</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <div dangerouslySetInnerHTML={{__html:emailTemplate}}/>
                </ModalBody>
      
                <ModalFooter>
                  <Button colorScheme='purple' variant={'outline'} mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      } 
    onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
    })
    function ConfirmAction({type}) {
        const { isOpen, onOpen, onClose } = useDisclosure()
        const cancelRef = React.useRef()
       
        return (
          <>
          {type === 'DELETE' && <>
          <Button onClick={()=>onOpen()} colorScheme={'red'} variant='outline' display={{base:'none',md:'block'}}>
                Delete Campaign
            </Button>
            <IconButton onClick={()=>onOpen()} colorScheme={'red'} icon={<FiTrash size={22}/>} display={{base:'inline-flex',md:'none'}}/>
          </>}
          {type == 'UPDATE' && <>
          <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='outline'
            onClick={()=>onOpen()}
            >Cancel</Button>
          </>}  

            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    { type == 'DELETE' ? 'Delete Campaign' : 'Cancel Changes'}
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    {type == 'DELETE' && <Text>Are you sure? All Submissions related to this Campaign will also be deleted.</Text>}
                    {type == 'UPDATE' && <Text>Are you sure? All Changes will be deleted.</Text>}
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button onClick={()=>onClose()}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={()=>{
                        switch(type) {
                            case 'DELETE':
                                deleteCampaign()
                            break
                            case 'UPDATE':
                                history.replace('/dashboard')
                            break
                            default:
                                return
                        }
                    }} ml={3}>
                      {type == 'DELETE' ? 'DELETE' : "Yes, I'm sure"}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )
      }
      const [reviewState,setReviewState] = useState('')
      const [campaignDetails,setCampaignDetails] = useState(null)
      const [redirectButtonVisible,setRedirectButtonVisible] = useState(false)
      const [formHeading,setFormHeading] = React.useState('')

      const [reviewFormSettings,setReviewFormSettings] = useState({})
      const [warrantyFormSettings,setWarrantyFormSettings] = useState({})
      const [registrationFormSettings,setResigtrationFormSettings] = useState({})
      const [accessToken,setAccessToken] = React.useState('')
      const handleRedirectButtonChanges = (switcherState) => {
        setThankYouPageSettings({...thankYouPageSettings,isRedirectButtonVisible:switcherState})
        setRedirectButtonVisible(switcherState)
    }
      const [thankYouPageSettings,setThankYouPageSettings] = useState()

    const handleThankYouPageSettingsChanges = (data) => {
        setThankYouPageSettings({...thankYouPageSettings,...data})
    }

      const handleSatisfied = () => setReviewState('Satisfied')
      const handleUnSatisfied = () => setReviewState('Unsatisfied')

      onAuthStateChanged(auth,currentUser=>{
        setAccessToken(currentUser.accessToken)
    })
      const renderFormBooleans = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewFormSettings
            break
            case 'REGISTRATION':
                return registrationFormSettings
            break
            case 'WARRANTY':
                return warrantyFormSettings
            break
            default:
                return reviewFormSettings
        }
    }
    const handleSubmissionFormChanges = (data) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewFormSettings({...reviewFormSettings,...data})
            break
            case 'REGISTRATION':
                setResigtrationFormSettings({...registrationFormSettings,...data})
            break
            case 'WARRANTY':
               setWarrantyFormSettings({...warrantyFormSettings,...data})
            break
            default:
                setReviewFormSettings({...reviewFormSettings,...data})
        }
    }


    const [campaignNameErrMssg,setCampaignNameErrMssg] = React.useState('')
    const [formHeadingErrMssg,setFormHeadingErrMssg] = React.useState('')
    const [thankYouPageHeadingErrMssg,setThankYouPageHeadingErrMssg] = React.useState('')
    const [thankYouPageSubHeadingErrMssg,setThankYouPageSubHeadingErrMssg] = React.useState('')
    const [sendAutomatedEmailAfterSubmission,setAutomatedEmailAfterSubmission] = React.useState(false)

    const fetchCampaignData = () => {
        axios.get(`${BASEURL}/campaigns/${campaignId}`)
        .then(res=>{
            const {
                campaign_id,
                seller_id,
                campaign_name,
                campaign_description,
                campaign_type,
                campaign_heading,
                campaign_image,
                scans_number,
                order_id_is_required,
                phone_number_is_required,
                whatsapp_number_is_required,
                model_number_is_required,
                product_id_is_required,
                shipment_number_is_required,
                media_is_required,
                custom_message_is_required,
                thank_you_heading,
                thank_you_sub_heading,
                thank_you_redirect_url,
                thank_you_redirect_text,
                thank_you_is_redirect_visible,
                send_automated_email,
                template_email
            } = res.data
            setFormHeading(campaign_heading)
            setCampaignDetails({
                campaignType:campaign_type,
                campaignName:campaign_name,
                campaignDescription:campaign_description,
                campaignImage:campaign_image
            })
            setEmailTemplate(template_email)
            setAutomatedEmailAfterSubmission(dbBooleansMapper(send_automated_email))
            setReviewFormSettings({
                orderID:dbBooleansMapper(order_id_is_required),
                phoneNumber:dbBooleansMapper(phone_number_is_required),
                whatsappNumber:dbBooleansMapper(whatsapp_number_is_required),
                modelNumber:dbBooleansMapper(model_number_is_required),
                productID:dbBooleansMapper(product_id_is_required),
                shipmentNumber:dbBooleansMapper(shipment_number_is_required),
                media:dbBooleansMapper(media_is_required),
                customMessage:dbBooleansMapper(custom_message_is_required)
            })

                setWarrantyFormSettings({
                orderID:dbBooleansMapper(order_id_is_required),
                phoneNumber:dbBooleansMapper(phone_number_is_required),
                whatsappNumber:dbBooleansMapper(whatsapp_number_is_required),
                modelNumber:dbBooleansMapper(model_number_is_required),
                productID:dbBooleansMapper(product_id_is_required),
                shipmentNumber:dbBooleansMapper(shipment_number_is_required),
                media:dbBooleansMapper(media_is_required),
                customMessage:dbBooleansMapper(custom_message_is_required)
                })

                setResigtrationFormSettings({
                    orderID:dbBooleansMapper(order_id_is_required),
                    phoneNumber:dbBooleansMapper(phone_number_is_required),
                    whatsappNumber:dbBooleansMapper(whatsapp_number_is_required),
                    modelNumber:dbBooleansMapper(model_number_is_required),
                    productID:dbBooleansMapper(product_id_is_required),
                    shipmentNumber:dbBooleansMapper(shipment_number_is_required),
                    media:dbBooleansMapper(media_is_required),
                    customMessage:dbBooleansMapper(custom_message_is_required)
                })
                setThankYouPageSettings(
                    {
                        heading:thank_you_heading,
                        subheading:thank_you_sub_heading,
                        redirectUrl:thank_you_redirect_url,
                        redirectButtonText:thank_you_redirect_text,
                        isRedirectButtonVisible:dbBooleansMapper(thank_you_is_redirect_visible)
                    }
                )
            console.log(res.data)
            console.log(campaignDetails)

            setIsCampaignDataFetched(true)
        })
    }
    const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }
    const dbBooleansMapper = value => {
        if(value == "0") return false
        if(value == "1") return true
    }
// console.log(campaignId)
    const submitChanges = () => {
        let obj = {
            ...campaignDetails,
            ...reviewFormSettings,
            ...warrantyFormSettings,
            ...thankYouPageSettings,
            formHeading,
            emailTemplate,
            sendAutomatedEmailAfterSubmission
        }

        axios.post(`${BASEURL}/campaigns/edit`,{
            // data to be edited
            campaignId,
            ...obj
        },{
            params:{
                accessToken
            }
        }).then(res=>{
            history.replace('/dashboard')
        })
    }

    const handleFocus = () => {
        setCampaignNameErrMssg('')
        setThankYouPageHeadingErrMssg('')
        setThankYouPageSubHeadingErrMssg('')
    }
    const validateAndSubmit = () => {
        let Errmsg = 'This field cannot be empty!'
        let isDirty = false

        if(campaignDetails['campaignName'].length == 0) {setCampaignNameErrMssg(Errmsg);isDirty = true}
        if(formHeading.length == 0) {setFormHeadingErrMssg(Errmsg);isDirty = true}
        if(thankYouPageSettings['heading'].length == 0) { setThankYouPageHeadingErrMssg(Errmsg);isDirty = true}
        if(thankYouPageSettings['subheading'].length == 0) {setThankYouPageSubHeadingErrMssg(Errmsg);isDirty = true}

        if(!isDirty) submitChanges()
        else return
    }
   

    const deleteCampaign = () => {
        axios.delete(`${BASEURL}/campaigns/${campaignId}`,{
            params:{
                accessToken:user.accessToken
            }
        })
        .then(res=>{
            history.replace('/dashboard')
        })
    }
    React.useEffect(()=>{
        fetchCampaignData()
    },[])
    return (
        <Box
        w={'full'}
        boxShadow='md'
        >
            
            {(isCampaignDataFetched == false) ? <Loading /> : <>
            <Box>
              {/* Header */}
              <Box
            // bgColor="green.200"
            display={'flex'}
            justifyContent={'space-between'}
            alignItems='center'
            p={10}
            >
            <Text as='h1' fontSize='3xl'>Edit Campaign</Text>
            <ConfirmAction type='DELETE'/>
            </Box>
            <Divider />
            {/* Campaign Details */}
            <Box p={10}>
                <Box>
                <FormControl isRequired my={{base:2,md:0}}>
                    <FormLabel htmlFor='campaignType'>Campaign name</FormLabel>
                    <Input id='campaignType' type='text' 
                    value={campaignDetails.campaignName}
                    onFocus={()=>handleFocus()}
                    onChange={e=>setCampaignDetails({...campaignDetails,campaignName:e.target.value})}
                    // onFocus={()=>handleInputFocus()}
                    />
                    <FormHelperText color='red.300'>{campaignNameErrMssg}</FormHelperText>
                </FormControl>

                
             {/* Product Description */}

             <FormControl isRequired mb={4}>
                <FormLabel htmlFor='productDescription'>Description</FormLabel>
                <Textarea 
                value={campaignDetails.campaignDescription}
                placeholder='Short description for your product ....'
                onChange={e=>setCampaignDetails({...campaignDetails,campaignDescription:e.target.value})}
                // onFocus={()=>handleInputFocus()}
                maxH={150}
                />
                 {/* <FormHelperText  pos="absolute" bottom={-5} color='red.300'>{campaignDescriptionError}</FormHelperText> */}
            </FormControl>


                </Box>
            </Box>

            <Divider />

            {/* Form Settings */}
            <Box
          py={6}
          px={10}
          mt={4}
          rounded='md'
        >
            <Box my={2}>
                <Text as='span' fontSize='xl'>Form Settings</Text>
                <Box w={{base:'100%',md:'50%'}} my={2}>
                <FormControl isRequired>
                    <FormLabel htmlFor='submissionHeading'>Heading</FormLabel>
                    <Input id='submissionHeading' type='text'
                    value={formHeading}
                    onChange={e=>setFormHeading(e.target.value)}
                    onFocus={()=>handleFocus()}
                    />
           <FormHelperText color='red.300'>{formHeadingErrMssg}</FormHelperText>
                </FormControl>
                </Box>
                <Text as='span' fontSize='lg' my={2}>Extra Fields</Text>
                <Flex w={'100%'} gap={5}
                direction={{base:'column',md:'row'}}
                >
                    <SimpleGrid flex={1} 
                    columns={2}
                    maxH={200}
                    spacingX={4}
                    spacingY={2}
                    >
           
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerNameIsVisible' mb='0'>Name</FormLabel>
                        <Switch id='buyerNameIsVisible' isChecked isDisabled/>
                        </Flex>
   
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerAddressIsVisible' mb='0'>Email address</FormLabel>
                        <Switch id='buyerAddressIsVisible' isChecked isDisabled/>
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerOrderIdIsVisible' mb='0'>Order ID</FormLabel>
                        <Switch id='buyerOrderIdIsVisible' isChecked={renderFormBooleans().orderID} isDisabled={renderFormBooleans().orderID}
                        onChange={(e)=>handleSubmissionFormChanges({orderID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerPhoneNumberIsVisible' mb='0'>Phone Number</FormLabel>
                        <Switch id='buyerPhoneNumberIsVisible' isChecked={renderFormBooleans().phoneNumber}
                        onChange={(e)=>handleSubmissionFormChanges({phoneNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerWhatsappNumberIsVisible' mb='0'>Whatsapp Number</FormLabel>
                        <Switch id='buyerWhatsappNumberIsVisible' isChecked={renderFormBooleans().whatsappNumber}
                        onChange={(e)=>handleSubmissionFormChanges({whatsappNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerModelNumberIsVisible' mb='0'>Model Number</FormLabel>
                        <Switch id='buyerModelNumberIsVisible' isChecked={renderFormBooleans().modelNumber}
                        onChange={(e)=>handleSubmissionFormChanges({modelNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerProductIdIsVisible' mb='0'>Product ID</FormLabel>
                        <Switch id='buyerProductIdIsVisible' isChecked={renderFormBooleans().productID}
                        onChange={(e)=>handleSubmissionFormChanges({productID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerShipmentNumberIsVisible' mb='0'>Shipment Number</FormLabel>
                        <Switch id='buyerShipmentNumberIsVisible' isChecked={renderFormBooleans().shipmentNumber}
                        onChange={(e)=>handleSubmissionFormChanges({shipmentNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerImageIsVisible' mb='0'>Include Image</FormLabel>
                        <Switch id='buyerImageIsVisible' isChecked={renderFormBooleans().media} isDisabled={campaignDetails.campaignType == 'REVIEW' ? true : false}
                         onChange={(e)=>handleSubmissionFormChanges({media:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerCustomMessageIsVisible' mb='0'>Custom Message</FormLabel>
                        <Switch id='buyerCustomMessageIsVisible' isChecked={renderFormBooleans().customMessage}
                         onChange={(e)=>handleSubmissionFormChanges({customMessage:e.target.checked})}
                        />
                        </Flex>
                  
                    </SimpleGrid>
                    <Divider orientation='vertical' />
                    <Box flex={1} 
                    // bgColor="green.200" 
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    mt={{base:10,md:0}}
                    >
                        <Flex direction={'column'}
                        w={{base:'90%',md:'80%'}}
                        // bgColor='blackAlpha.100'
                        py={3}
                        px={2}
                        borderRadius={10}
                        boxShadow='lg'
                        >
                        <Box w={'60%'} textAlign='left'>
                        <Text as="span"
                        fontSize={22}
                        fontWeight='semibold'
                        >
                       {formHeading}
                        </Text>
                        </Box>
                       {campaignDetails.campaignType == 'REVIEW' && <Box>
                       <Box
                        display='flex'
                        // bgColor="purple.300"
                        justifyContent={'center'}
                        py={4}
                        gap={4}
                        >
                            <FiThumbsUp size={40} onClick={()=>handleSatisfied()}
                            color={reviewState == 'Satisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                            <FiThumbsDown size={40} onClick={()=>handleUnSatisfied()}
                            color={reviewState == 'Unsatisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                        </Box>
                        <Text textAlign={'center'}>{reviewState}</Text>
                       </Box>}
                        <Box> 
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Name</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Email address</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        {renderFormBooleans().phoneNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Phone Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().whatsappNumber &&  <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Whatsapp Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {renderFormBooleans().orderID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Order ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {(campaignDetails.campaignType == 'REVIEW' && reviewState == 'Unsatisfied') && <FormControl isRequired>
                        <FormLabel htmlFor='negative_review'>Write a review</FormLabel>
                        <Textarea 
                        id="negative_review"
                        placeholder='Tell us what went wrong and how we can improve'
                        />
                        </FormControl>}
                        {renderFormBooleans().productID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Product ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().modelNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Model Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().shipmentNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Shipment Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().media && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Include a screenshot of the review</FormLabel>
                        <Input id='campaignType' type='file' />
                        </FormControl>}
                        {renderFormBooleans().customMessage && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Custom Message</FormLabel>
                        <Textarea />
                        </FormControl>}
                        <Box>
                            <Button w={'full'} colorScheme='purple'
                            >Submit</Button>
                        </Box>
                        </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
            </Box>

            <Divider />

            {/* Thank You Page Settings */}

            <Box
         py={6}
         px={10}
        mt={4}
        rounded='md'
        boxShadow='base'
        >
         <Box my={2}>
                <Text as='span' fontSize='lg'>Thank You Page Settings</Text>
                <Flex w={'100%'}
                //  bgColor="purple.100"
                direction={{base:'column',md:'row'}}
                   gap={5}>
                    <Box flex={1} 
                    // bgColor="orange.200"
                    >
                    <FormControl isRequired>
                        <FormLabel htmlFor='thankYouPageHeading'>Heading</FormLabel>
                        <Input id='thankYouPageHeading' type='text'
                        value={thankYouPageSettings.heading}
                        onFocus={()=>handleFocus()}
                        onChange={(e)=>handleThankYouPageSettingsChanges({heading:e.target.value})}
                         />
                <FormHelperText color='red.300'>{thankYouPageHeadingErrMssg}</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='thankYouPageSubheading'>Subheading</FormLabel>
                        <Input id='thankYouPageSubheading' type='text'
                         value={thankYouPageSettings.subheading}
                         onFocus={()=>handleFocus()}
                         onChange={(e)=>handleThankYouPageSettingsChanges({subheading:e.target.value})}
                        />
                         <FormHelperText color='red.300'>{thankYouPageSubHeadingErrMssg}</FormHelperText>
                    </FormControl>
                    <FormControl display='flex' alignItems='center' my={2}>
                        <FormLabel htmlFor='redirect_button' mb='0'>
                            Show Redirect Button?
                        </FormLabel>
                        <Switch id='redirect_button' onChange={(e)=>handleRedirectButtonChanges(e.target.checked)} isChecked={thankYouPageSettings.isRedirectButtonVisible} />
                        </FormControl>
                        {thankYouPageSettings.isRedirectButtonVisible && <>
                            <FormControl isRequired my={2}>
                        <FormLabel htmlFor='redirect_url'>URL</FormLabel>
                        <Input id='redirect_url' type='text'
                         value={thankYouPageSettings.redirectUrl}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectUrl:e.target.value})}
                         />
                    </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='redirect_button_text'>Redirect Button Text</FormLabel>
                        <Input id='redirect_button_text' type='text'
                         value={thankYouPageSettings.redirectButtonText}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectButtonText:e.target.value})}
                        />
                    </FormControl>
                        </>}
                        {campaignDetails.campaignType == 'REVIEW' && <FormControl display='flex' alignItems='center' my={2}>
                        <FormLabel htmlFor='automated_email' mb='0'>
                            After Submission E-mail
                        </FormLabel>
                        <Switch id='automated_email' onChange={(e)=>setAutomatedEmailAfterSubmission(e.target.checked)} isChecked={sendAutomatedEmailAfterSubmission} />
                        </FormControl>}
                       
                    </Box>
                    <Divider orientation='vertical' />
                    <Box flex={1} 
                    // bgColor="green.200" 
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    flexDirection='column'
                    >
                        <Flex direction={'column'} alignItems='center'
                        w={{base:'90%',md:'80%'}}
                        maxW={{base:'90%',md:'80%'}}
                        wordBreak='break-word'
                        my={2}
                        minH={300}
                        maxH={300}
                        overflowY='hidden'
                        borderRadius={10}
                        py={4}
                        boxShadow='lg'
                        >
                        <Box>
                        <Text as="span"><FiCheck size={60}/></Text>
                        </Box>
                        <Box>
                            <Text as="h1" fontSize={32} fontWeight='semibold'>{thankYouPageSettings.heading}</Text>
                            <Text as="h2" fontSize={22} fontWeight='semibold'>{thankYouPageSettings.subheading}</Text>
                        </Box>
                        <Box my={2}>
                            {thankYouPageSettings.isRedirectButtonVisible && <Button colorScheme={'purple'}>
                                {thankYouPageSettings.redirectButtonText}
                            </Button>}
                        </Box>
                        </Flex>
                       {(sendAutomatedEmailAfterSubmission && campaignDetails.campaignType == 'REVIEW') &&  <Flex w={{base:'90%',md:'80%'}}>
                            <FormControl my={2}>
                        <FormLabel display='flex' alignItems='center' justifyContent={'space-between'} mb='0'>
                        <Text>E-Mail Template</Text>
                        <Button variant={'link'} colorScheme='purple'>
                            <TemplateVisualizationModal />
                        </Button>
                        </FormLabel>
                        <Textarea
                        variant={'filled'}
                        minH={200}
                        value={emailTemplate}
                        onChange={e=>setEmailTemplate(e.target.value)}
                        >

                        </Textarea>
                        </FormControl>
                            </Flex>}
                    </Box>
                </Flex>
            </Box>
        </Box>

        <Divider />    

        <Box my={10}>
        <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='solid'
            // onClick={()=>submitCampaign()}
            onClick={()=>validateAndSubmit()}
            mr={4}
            >Save</Button>
           <ConfirmAction type='UPDATE'/>
          </Box>
             
            </Box>
            </>}
        </Box>
    );
}
 
export default EditCampaign;