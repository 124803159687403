
import React from 'react'
import { Flex } from '@chakra-ui/react'
import Logo from './Logo'
import MenuLinks from './MenuLinks'
import MenuToggle from './MenuToggle'
import { useLocation } from 'react-router-dom'
const NavBarContainer = ({ children, ...props }) => {

  const location = useLocation()
  
  const isCampaignPage = () => {
    if(location.pathname.startsWith('/c/')) return true
    return false
  }
    return (
      <Flex
        as="nav"
        align={isCampaignPage() ? "flex-start" : 'center'}
        justify="space-between"
        wrap="wrap"
        w="100%"
        mb={8}
        p={4}
        h={isCampaignPage() && 200}
        bgColor='purple.300'
        color={["white", "white", "primary.700", "primary.700"]}
        borderBottomLeftRadius={isCampaignPage() && 50}
        borderBottomRightRadius={isCampaignPage() && 50}
        boxShadow='md'
        {...props}
      >
        {children}
      </Flex>
    )
  }

const Navbar = (props) => {
    const [isOpen, setIsOpen] = React.useState(false)
  
    const toggle = () => setIsOpen(!isOpen)
  
    return (
      <NavBarContainer {...props}>
        <Logo
          w="100px"
          color={["white", "white", "primary.500", "primary.500"]}
        />
        <MenuToggle toggle={toggle} isOpen={isOpen} />
        <MenuLinks isOpen={isOpen} setIsOpen={setIsOpen} />
      </NavBarContainer>
    )
  }

export default Navbar