import React from "react"
import { Box } from "@chakra-ui/react"
import { FiMenu,FiX } from 'react-icons/fi'
const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <FiX size={22} /> : <FiMenu size={22} />}
    </Box>
  )
}

export default MenuToggle