import React, { useState } from 'react'
import {
    Tabs, TabList, TabPanels, Tab, TabPanel,Button,
    Box,
    Flex
} from '@chakra-ui/react'
import CreateCampaign from './CreateCampaign';
import Analytics from './Analytics';
import Metadata from '../../Components/Metadata';
import UsersListTab from './UserList.jsx/UsersListTab';
const Dashboard = () => {
    const [activeIndex,setActiveIndex] = useState(1)
    const handleChange = (index) => {
        setActiveIndex(index)
    }
    return (
        
        <Tabs orientation='vertical' w={'100%'} isLazy={true}
        index={activeIndex}
        onChange={handleChange}
        >
            <Metadata title='Dashboard'/>
          <Flex
          w={'full'}
          gap={0}
          direction={{base:'column',md:'row'}}
          >
          <Box w={{base:'100%',md:'13%'}}>
            <TabList
            bgColor={'blackAlpha.200'}
            boxShadow='md'
            >
                <Tab>Create Campaign</Tab>
                <Tab>Analytics</Tab>
                <Tab>Users List</Tab>
            </TabList>
            </Box>

           <Box w={{base:'100%',md:'87%'}} >
          
           <TabPanels  
        //   bgColor={'red.200'}
          >
                <TabPanel>
                    <CreateCampaign setActiveIndex={setActiveIndex}/>
                </TabPanel>
                <TabPanel>
                    <Analytics />
                </TabPanel>
                <TabPanel>
                    <UsersListTab />
                </TabPanel>
            </TabPanels>
           </Box>
          </Flex>
        </Tabs>
    );
}

export default Dashboard;