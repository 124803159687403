import React from 'react'
import {
    Box,
    Text,
    Image,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Spinner,
    Textarea,
    Center,
    Link,
    FormHelperText,CircularProgress, CircularProgressLabel,
    useToast
} from '@chakra-ui/react'
import {  useParams } from 'react-router-dom';
import { FiCheck, FiThumbsUp, FiThumbsDown } from 'react-icons/fi'
import axios from 'axios'
import { useState,useEffect } from 'react';
import { BASEURL } from '../../api';
import Metadata from './../../Components/Metadata'
import AWS from 'aws-sdk';
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer; 
const Campaign = () => {
    const fileRef = React.useRef()
    const toast = useToast()
    const [progress,setProgress] = React.useState(0)
    const [clientMediaUrl,setClientMediaUrl] = React.useState('')
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const { campaignId } = useParams()
    const [campaignData,setCampaignData] = useState([]) 
    const [isCampaignFetched,setIsCampaignFetched] = useState(false)
    const [reviewState,setReviewState] = useState('')
    const [formVisible,setFormVisible] = useState(false)
    const [thankYouVisible,setThankYouVisible] = useState(false)
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }
    const [buyerData,setBuyerData] = useState({
        name:'',
        email:'',
        orderID:'',
        phoneNumber:'',
        whatsappNumber:'',
        modelNumber:'',
        productID:'',
        shipmentNumber:'',
        media:'',
        customMessage:'',
        negativeReview:''
    })
    const handleSatisfied = () => setReviewState('Satisfied')
    const handleUnSatisfied = () => setReviewState('Unsatisfied')

    const handleFormChanges = (data) => {
        setBuyerData({...buyerData,...data})
    }

    const [nameErrMssg,setNameErrMssg] = React.useState('')
    const [emailErrMssg,setEmailErrMssg] = React.useState('')
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [orderIdErrMssg,setOrderIdErrMssg] = React.useState('')
    
    const isValidEmail = (email) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
        return regex.test(email)
    }
    const validateAndSubmit = () => {
        let Errmsg = 'This field cannot be empty!'
        let isDirty = false
        if(campaignData['campaign_type'] == "WARRANTY") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)){ setEmailErrMssg(Errmsg);isDirty = true}
            if(buyerData.orderID.length == 0) {setOrderIdErrMssg(Errmsg);isDirty = true}
        }
        if(campaignData['campaign_type'] == "REVIEW") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)){ setEmailErrMssg(Errmsg);isDirty = true}
            if(buyerData.orderID.length == 0) {setOrderIdErrMssg(Errmsg);isDirty = true}
            // if(buyerData.media.length == 0) {setMediaErrMssg(Errmsg);isDirty = true}
            // another test for image
            
        }  if(campaignData['campaign_type'] == "REGISTRATION") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)) {setEmailErrMssg(Errmsg);isDirty = true}
            // another test for image
            
        }
        if(!isDirty) submitForm()
    }
    const submitForm = () => {
        axios.post(`${BASEURL}/submissions/newSubmission`,{
            campaignId,
            reviewState,
            sellerId:campaignData.seller_id,
            submissionType:campaignData.campaign_type,
            ...buyerData,
        }).then((result)=>{
            if(result.status == 200 && result.data.created) {
            if(campaignData.campaign_type == 'REVIEW' && campaignData['send_automated_email'] == "1" ){
                axios.post(`${window.location.origin}/reviewEmail`,{
                    clientMail:buyerData.email,
                    uid:campaignData['seller_id'],
                    clientName:buyerData.name,
                    htmlTemplate:campaignData['template_email']
                }).then(res=>{
                    console.log('sent')
                })
            }
            setThankYouVisible(true)
            }
        }).catch(err=>console.log('err submiting',err))
    }

    const hanleFormFocus = () => {
        setNameErrMssg('')
        setEmailErrMssg('')
        setOrderIdErrMssg('')
        setMediaErrMssg('')
    }

    const renderButtonText = (type) => {
        switch(type) {
            case 'REVIEW':
                return 'Review Product'
            break
            case 'REGISTRATION':
                return 'Register Product'
            break
            case 'WARRANTY':
                return 'Activate Warranty'
            break
            default:
                return ''
        }
    }

    const incrementScan = () => {
        axios.put(`${BASEURL}/campaigns/increment-scan-number`,{
            campaignId
        })
    }

    const fetchCampaign = () => {
        axios.get(`${BASEURL}/campaigns/${campaignId}`)
        .then(res=>{
            console.log(res.data)
            setCampaignData(res.data)
            setIsCampaignFetched(true)

            incrementScan()
        }).catch(err=>console.log(err))
    }

    function createObjectURL(object) {
        return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
    }
    const [localImageSrc,setLocalImageSrc] = React.useState(null)

const handleImageChange = (e) => {
    // reset conditions 
    setMediaErrMssg('')
    setMediaUploaded(false)
    setProgress(0)
    setLocalImageSrc(null)
    let file = e.target.files[0]
    let fileName = file.name
    
    if(file.size > 1 * 1024 * 1024){
        showToast('Upload Error','The file size must be less than or equal to 10MB','warning',3000)
        return
    }
    setLocalImageSrc(createObjectURL(file))


    const s3 = new AWS.S3({
        correctClockSkew: true,
        endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
        region: 'us-west-1',
        accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
        secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
        logger: console,
        signatureVersion:'v4',
      });

    const uid = new ShortUniqueId()
  let newUID = uid()
  const uploadRequest = new AWS.S3.ManagedUpload({
    params: { Bucket: process.env.REACT_APP_WASABI_BUCKETNAME, Key: `${newUID}-${fileName}`, Body: file },
    service: s3
  });

  uploadRequest.on('httpUploadProgress', function(event) {
    const progressPercentage = Math.floor(event.loaded * 100 / event.total);
    console.log('Upload progress ' + progressPercentage);
    setProgress(progressPercentage)
  });


  uploadRequest.send(function(err) {
    if (err) {
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
    } else {
        setMediaUploaded(true)
        let url = `https://s3.us-east-1.wasabisys.com/${process.env.REACT_APP_WASABI_BUCKETNAME}/${newUID}-${fileName}`
        setBuyerData({...buyerData,media:url})
    }
  });


}

    useEffect(()=>{
        fetchCampaign()     
    },[])

    return (
        <Container maxW={'container.xl'}>
            <Metadata title={'New Submission'}/>
            <Box
                pos="absolute"
                top={90}
                // bgColor="red.100"
                left={'50%'}
                style={{ transform: 'translateX(-50%)' }}
                w={'70%'}
            >
               {(isCampaignFetched == false) ? <Box><Spinner /></Box> : <>
               <Box
                    display="flex" flexDirection='column' alignItems={'center'}>
                    <Box w={{ md: 650 }}
                    >
                        <Text
                            fontSize={33}
                            fontWeight='semibold'
                            mb={4}
                        >{campaignData['campaign_name']}</Text>
                        <Image
                            src={campaignData['campaign_image']}
                        />
                    </Box>

                  {campaignData['campaign_description'] && <Box 
                    mt={4}
                    px={2}
                    py={4}
                    // bgColor='purple.100'
                    borderRadius={10}
                    w={{base:'100%',md:'60%'}}
                    boxShadow='md'
                    >
                    <Text as="h2" fontSize='2xl'>Description</Text>
                    <Text as="p" fontSize='lg'>{campaignData['campaign_description']}</Text>
                </Box>}
                </Box>
                {!formVisible &&  <Box        
                    display="flex"
                    justifyContent={'center'}
                    alignItems='center'
                    my={4}
                >
                    <Box
                     w={{base:'100%',md:'60%'}}
                    //  bgColor="purple.100"
                     py={50}
                     display='flex'
                     justifyContent={'center'}
                     boxShadow='md'
                    >
                            {campaignData.length == 0 ? <>
                            <Text fontSize='2xl'>No Campaigns Found!</Text>
                            </> : <Button size='lg' colorScheme={'purple'}
                    onClick={()=>setFormVisible(true)}
                    >
                        <Text>{renderButtonText(campaignData['campaign_type'])}</Text>
                    </Button>}
                          
                    </Box>
                  
                </Box>} 
               {(formVisible && !thankYouVisible) &&  <Box flex={1}
                    my={4}
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                >
                    
                    <Box flex={1} 
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    >
                        <Flex direction={'column'}
                        w={{base:'100%',md:'60%'}}
                        // bgColor='purple.100'
                        py={3}
                        px={{base:3,md:10}}
                        borderRadius={10}
                        boxShadow='lg'
                        >
                        <Box w={{base:'80%',md:'60%'}} textAlign='left' mt={2} mb={4} 
                        >
                        <Text as="span"
                        fontSize={22}
                        fontWeight='semibold'
                        >
                      {campaignData.campaign_heading}
                        </Text>
                        </Box>
                       {(campaignData.campaign_type == "REVIEW") && <Box>
                       <Box
                        display='flex'
                        justifyContent={'center'}
                        py={4}
                        gap={4}
                        >
                            <FiThumbsUp size={40} onClick={()=>handleSatisfied()}
                            color={reviewState == 'Satisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                            <FiThumbsDown size={40} onClick={()=>handleUnSatisfied()}
                            color={reviewState == 'Unsatisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                        </Box>
                        <Text textAlign={'center'}>{reviewState}</Text>
                       </Box>}
                        <Box> 
                        <FormControl isRequired>
                        <FormLabel htmlFor='buyerName'>Name</FormLabel>
                        <Input id='buyerName' type='text' 
                        onChange={(e)=>handleFormChanges({name:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        <FormHelperText textAlign={'left'} color="red.300">{nameErrMssg}</FormHelperText>
                        </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='buyerEmail'>Email address</FormLabel>
                        <Input id='buyerEmail' type='text' 
                        onChange={(e)=>handleFormChanges({email:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                         <FormHelperText textAlign={'left'} color="red.300">{emailErrMssg}</FormHelperText>
                        </FormControl>
                        {(campaignData.phone_number_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerPhoneNumber'>Phone Number</FormLabel>
                        <Input id='buyerPhoneNumber' type='text' 
                        onChange={(e)=>handleFormChanges({phoneNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        {(campaignData.whatsapp_number_is_required == "1") &&  <FormControl isRequired>
                        <FormLabel htmlFor='buyerWhatsappNumber'>Whatsapp Number</FormLabel>
                        <Input id='buyerWhatsappNumber' type='text'
                        onChange={(e)=>handleFormChanges({whatsappNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                       {(campaignData.order_id_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerOrderId'>Order ID</FormLabel>
                        <Input id='buyerOrderId' type='text'
                        onChange={(e)=>handleFormChanges({orderID:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        <FormHelperText textAlign={'left'} color="red.300">{orderIdErrMssg}</FormHelperText>
                        </FormControl>}
                         {(campaignData.campaign_type == 'REVIEW' && reviewState == 'Unsatisfied') && <FormControl isRequired>
                        <FormLabel htmlFor='negative_review'>Write a review</FormLabel>
                        <Textarea 
                        id="negative_review"
                        placeholder='Tell us what went wrong and how we can improve'
                        onChange={(e)=>handleFormChanges({negativeReview:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        {(campaignData.product_id_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerProductId'>Product ID</FormLabel>
                        <Input id='buyerProductId' type='text' 
                        onChange={(e)=>handleFormChanges({productID:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        {(campaignData.model_number_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerModelNumber'>Model Number</FormLabel>
                        <Input id='buyerModelNumber' type='text'
                        onChange={(e)=>handleFormChanges({modelNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        {(campaignData.shipment_number_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerShipmentNumber'>Shipment Number</FormLabel>
                        <Input id='buyerShipmentNumber' type='text'
                        onChange={(e)=>handleFormChanges({shipmentNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        {(campaignData.media_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerMedia'>Include a screenshot of the review</FormLabel>
                        <Input id='buyerMedia' type='file' ref={fileRef} accept='image/*'
                        onChange={e=>handleImageChange(e)}
                        />
                        <Box
                        bgColor={'red.100'}
                        w={'30%'}
                        pos='relative'
                        >
                        {(mediaUploaded) && <Box
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        bgColor='#fff'
                        style={{transform:'translate(-50%,-50%)'}}
                        borderRadius='full'
                        p={1}
                        boxShadow='md'
                        zIndex={1000}
                        >
                        <FiCheck 
                        size={44}
                        color='#805ad5'
                        />  
                            </Box>}
                       
                        {((progress > 0 ) && ( progress < 100)) && <CircularProgress 
                        value={progress} color='purple.400' 
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        style={{transform:'translate(-50%,-50%)'}}
                        zIndex={1000}
                        >
                            <CircularProgressLabel>{progress}%</CircularProgressLabel>
                        </CircularProgress>}
                       {(localImageSrc != null) && <Image 
                        src={localImageSrc}
                        filter='auto'
                        blur={progress == 100 ? '0px' : '1.5px'}
                        />}
                        </Box>
                        <FormHelperText textAlign={'left'} color="red.300">{mediaErrMssg}</FormHelperText>
                    </FormControl>}
                        {(campaignData.custom_message_is_required == "1") && <FormControl isRequired>
                        <FormLabel htmlFor='buyerCustomMessage'>Custom Message</FormLabel>
                        <Textarea id='buyerCustomMessage' 
                        onChange={(e)=>handleFormChanges({customMessage:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        />
                        </FormControl>}
                        <Box my={4}>
                            <Button w={'full'} colorScheme='purple'
                            onClick={()=>validateAndSubmit()}
                            >Submit</Button>
                            <Button w={'full'} colorScheme='red' my={3}
                            variant='outline'
                            onClick={()=>setFormVisible(false)}
                            >Cancel</Button>
                        </Box>
                        </Box>
                        </Flex>
                    </Box>
                </Box>}
                {(thankYouVisible) && <Center>
               <Flex 
                        direction={'column'} 
                        alignItems='center'
                        justify='center'
                        // bgColor='purple.100'
                        w={{base:'100%',md:'60%'}}
                        maxW={'80%'}
                        wordBreak='break-word'
                        my={4}
                        minH={300}
                        maxH={300}
                        overflowY='hidden'
                        borderRadius={10}
                        textAlign="center"
                        boxShadow='lg'
                        >
                        <Box>
                        <Text as="span"><FiCheck size={60}/></Text>
                        </Box>
                        <Box>
                            <Text as="h1" fontSize={32} fontWeight='semibold'>{campaignData.thank_you_heading}</Text>
                            <Text as="h2" fontSize={22} fontWeight='semibold'>{campaignData.thank_you_sub_heading}</Text>
                        </Box>
                        <Box my={2}>
                            {(campaignData.thank_you_is_redirect_visible == "1" ) && <Button as={Link} href={campaignData.thank_you_redirect_url} colorScheme='purple' variant='solid'
                            _hover={{textDecoration:'none'}}
                            >
                                <Text>{campaignData.thank_you_redirect_text}</Text>
                            </Button>}
                        </Box>
                        </Flex>
               </Center>}
               </>}
            </Box>
        </Container>
    );
}

export default Campaign;