import React from 'react'
import {
  Box,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightAddon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Button,
  Text,
  Flex,
  Badge,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useHistory, Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { BASEURL } from '../../../api';
import { auth } from '../../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'

const Submissions = () => {
  const toast = useToast()
  const { campaignId } = useParams()
  const [emailTemplate, setEmailTempalte] = React.useState('')
  const [submissionsList, setSubmissionsList] = React.useState([])
  const [areSubmissionsFetched, setAreSubmissionsFetched] = React.useState(false)
  const [user, setUser] = React.useState(null)
  console.log(submissionsList)
  function TemplateVisualizationModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        {emailTemplate.length != 0 && <Button
          variant={'link'}
          colorScheme='purple'
          onClick={onOpen}
        >Visualize</Button>}

        <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
          <ModalOverlay />
          <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
            <ModalHeader>E-mail Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div dangerouslySetInnerHTML={{ __html: emailTemplate }} />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='purple' variant={'outline'} mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }


  const showToast = (title, description, status, duration) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable: true,
    })
  }


  const sqlBooleanMapper = (state) => {
    if (state == '0') return false
    if (state == '1') return true
  }
  const fetchSubmissions = () => {
    // pass the seller Id
    axios.get(`${BASEURL}/submissions/all-submissions/${campaignId}`, {
      params: {
        accessToken: user.accessToken
      }
    })
      .then(res => {
        console.log(res.data)
        setSubmissionsList(res.data.results.submissions)
        if (res.data.results.submissions.length != 0) {
          // setSubmissionsType(res.data.results.submissions[0]['submission_type'])
          // setTotalSubmissions(res.data.results.totalAnalytics.TotalSubmissions)
        }
        setAreSubmissionsFetched(true)
      }).catch(err => {
        console.log(err)
      })
  }

  const UserRecord = ({ email, submissionId, emailSent }) => {
    let isEmailSent = sqlBooleanMapper(emailSent)
    return (<>
      <Flex
        bgColor="purple.100"
        justify={'space-between'}
        px={10}
        py={2}
        my={3}
        borderRadius={3}
        boxShadow='base'
      >
        <Box w={'90%'}>
          <Text>{email}</Text>
        </Box>
        <Flex w={'10%'} justify='center'>
          {isEmailSent ?
            <Badge colorScheme='green' display='flex' alignItems={'center'}>SENT</Badge>
            : <Badge colorScheme='orange' display='flex' alignItems={'center'}>NOT SENT</Badge>
          }

        </Flex>
      </Flex>
    </>)
  }


  onAuthStateChanged(auth, currentUser => {
    setUser(currentUser)
    // console.log('user signed in',currentUser)
  })
  const sendMail = async () => {
    // console.log(submissionsList)
    const submissionsWithEmailsNotSent = submissionsList.filter(submission => {
      return (submission.email_sent != '1')
    })
    // console.log('submissionsWithEmailsNotSent',submissionsWithEmailsNotSent)
    if (submissionsWithEmailsNotSent != 0) {
      axios.post(`${BASEURL}/emails/send-mail`, {
        accessToken: user.accessToken,
        submissions: submissionsWithEmailsNotSent
      }).then((res) => {
        console.log(res.data)

        // axios.post(`${window.location.origin}/massEmail`, {
        //   submissions : submissionsWithEmailsNotSent,
        //   userAccessToken:user.accessToken,
        //   htmlTempalte  : emailTemplate,
        // }).then(res => {
        //   console.log('sent')
        // })
      
      })
    } else {
      showToast('Invalid Operation', "No Submissions Available!.", 'info', 1700)
    }
  }
  React.useEffect(() => {
    if (user) fetchSubmissions()
  }, [user])

  return (
    <Flex w={'100%'} gap={10}
      direction={{ base: 'column-reverse', md: 'row' }}
    >
      <Box w={{ base: '100%', md: '60%' }}>
        <Text as='h1' fontSize='xl'>Users List</Text>
        {submissionsList.map(submission => {
          return <UserRecord email={submission['email']} submissionId={submission['submission_id']} emailSent={submission['email_sent']} />
        })}
      </Box>
      <Box w={{ base: '100%', md: '40%' }}>
        <FormControl>
          <FormLabel
            display={'flex'}
            justifyContent='space-between'
            htmlFor='email'
            alignItems={'center'}
          >
            <Text>E-mail Template</Text>
            <TemplateVisualizationModal />
          </FormLabel>
          <Textarea placeholder='Paste HTML code here ...'
            variant={'filled'}
            minH={300}
            onChange={(e) => setEmailTempalte(e.target.value)}
          />
        </FormControl>
        <Button w={'full'} colorScheme='green' my={5}
          onClick={() => sendMail()}
        >
          SEND E-MAIL
        </Button>
      </Box>
    </Flex>
  );
}

export default Submissions;