// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAuth,GoogleAuthProvider } from '@firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    appId: process.env.REACT_APP_FIREBASE_APPID
  };
  
// const firebaseConfig = {
//     apiKey: 'AIzaSyAopuYGj2kz5wRKCU8Za2iCOh8WiRh3hMk',
//     authDomain: 'qr-scanner-sandbox-8ddd8.firebaseapp.com',
//     projectId: 'qr-scanner-sandbox-8ddd8',
//     appId: '1:713113421408:web:128e01634bb33f3a186505'
//   };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authProvider = new GoogleAuthProvider()
export const auth = getAuth(app)
