import React from 'react'
import { Box, Divider,Button,Text,Flex,Progress, FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,Input } from '@chakra-ui/react';
import { applyActionCode,onAuthStateChanged,signOut,verifyPasswordResetCode, confirmPasswordReset } from '@firebase/auth'
import { auth } from '../../api/firebase-config';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { BASEURL } from '../../api';

 
const EmailActions = () => {

    const [user,setUser] = React.useState(null)
    // Email Verification
    const [isVerifying,setIsVerifying] = React.useState(true)
    const [resultMessage,setResultMessage] = React.useState('')
    const [isVerified,setIsVerified] = useState(false)
    const [isExpiredLink,setExpiredLink] = useState(false)
    // Password Reset
    const [newPassword,setNewPassword] = React.useState('')
    const [passwordErrorMessage,setPasswordErrorMessage] = React.useState('')
    const [passwordResetSuccessMessage,setPasswordResetSuccessMessage] = React.useState('')
    const [isPasswordReset,setIsPasswordReset] = React.useState(false)
    const getoobCode = (url) =>{
        let oobString
        url.split('&').forEach(str=>{
        if(str.includes('oobCode')) oobString = str.split('=')[1]
        })
        console.log('oobstring',oobString)
        return oobString
    }

    function handleVerifyEmail(auth, actionCode) {

            applyActionCode(auth, actionCode).then((resp) => {
                onAuthStateChanged(auth,currentUser=>{
                axios.post(`${BASEURL}/sellers/activate_email`,{
                    uid : currentUser.uid
                }).then(res=>{
                    console.log(res.data)
                }).then(()=>{
                setIsVerified(true)
                setResultMessage('Your email has been verified.')
                }).then(()=>{
                    //sign out
                    signOut(auth)
                    localStorage.clear()
                    setTimeout(()=>{
                        window.location.reload()
                    },2000)
                })
                })
                 
            }).catch((error) => {
            // console.log('not verified',userUID?.uid)
             setExpiredLink(true)
             setResultMessage('The verification link is invalid or expired.')
            }).finally(()=>setIsVerifying(false))
           }

    function handlePasswordReset(auth, actionCode) {
            
          verifyPasswordResetCode(auth, actionCode).then((email) => {
            confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
               setIsPasswordReset(true)
            }).catch((error) => {
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
              setPasswordErrorMessage('Your password is weak or too short.')
            });
          }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            setPasswordErrorMessage('The reset link is expired! Try again')
          });
        }


           
       
    //   console.log('Current User State Changed',user )
    //   console.log('Current User Auth',auth.currentUser )
      
      const currentAction = () => {
        let current = window.location.href.split('?')[1].split('&')[0].split('=')[1]
        return current
    }

    // console.log('the user uid',auth.currentUser?.uid)
    

      React.useEffect(()=>{

       if(currentAction() == 'verifyEmail') handleVerifyEmail(auth,getoobCode(window.location.href))

      },[])
    return (
      <>
    <Box
    boxShadow={'md'}
    >
        {/* Email Verification */}
        {currentAction() == 'verifyEmail' &&  <Box>
        <Box w={'100%'}>
    {isVerifying && <Progress size='xs' colorScheme='purple' isIndeterminate />}
    </Box>
    <Box
     display="flex"
     flexDirection={'column'}
     alignItems='center'
    py={7}
    px={7}
    >
    <Text as="h1" fontSize='lg' mb={4}>
        {isVerifying && 'Please wait ... We are verifiying'}
        {resultMessage}
    </Text>
    <Text as="h2" fontWeight={'semibold'}>
        {!isVerifying && isVerified && <>
            Please <Button variant={'link'} as={Link} to='/auth'>Sign in</Button> Again
        </>}
        {!isVerifying && isExpiredLink && <>
            Need help? <Button variant={'link'} as={Link} to='/contact'>Contact Us</Button>
        </>}
    </Text>
    </Box>
        </Box>}

         {/* Password Reset */}
        {currentAction() == 'resetPassword' && <Box>
    <Box>
        {!isPasswordReset ? <Box
        display="flex"
        flexDirection={'column'}
        alignItems='center'
       py={7}
       px={7}
       my={2}
        >
        <Text as="h1" fontSize='lg'>
        Reset Your Password
    </Text>
    <FormControl>
     <FormLabel htmlFor='email'>New password</FormLabel>
    <Input onFocus={()=>setPasswordErrorMessage('')} id='password' type='password' onChange={(e)=>setNewPassword(e.target.value)}/>
    <FormHelperText color='red.400'>{passwordErrorMessage}</FormHelperText>
    </FormControl>
    <Button colorScheme={'purple'} w={'full'} my={2} onClick={()=>handlePasswordReset(auth,getoobCode(window.location.href))}>
        Reset Password
    </Button>
        </Box> : <Box
        display="flex"
        flexDirection={'column'}
        alignItems='center'
       py={7}
       px={7}
       my={2}
        >
        <Text as="h1" fontSize='lg' mb={4} textAlign='center'>
        Password changed successfully.
    </Text>
    <Button variant={'ghost'} colorScheme='purple' as={Link} to='/auth'>Sign in</Button>
        </Box>}
    </Box>

    </Box>}
    
        </Box>
      </>
    );
}
 
export default EmailActions;