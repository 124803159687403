import React from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex
} from '@chakra-ui/react'
import Users from './Users'
import Plans from './Plans'
import Metadata from '../../Components/Metadata'

const AdminDashboard = () => {
  return (
    <Tabs orientation='vertical' w={'100%'} isLazy={true}>
      <Metadata title='Admin Dashboard' />
      <Flex
        w={'full'}
        gap={0}
        direction={{ base: 'column', md: 'row' }}
      >
        <Box w={{ base: '100%', md: '15%' }}>
          <TabList
            bgColor={'blackAlpha.200'}
            boxShadow='md'
          >
            <Tab>Users Management</Tab>
            <Tab>Plans Management</Tab>
          </TabList>
        </Box>

        <Box w={{ base: '100%', md: '85%' }} boxShadow='md' m={5}>

          <TabPanels>
            <TabPanel>
              <Users />
            </TabPanel>
            <TabPanel>
              <Plans />
            </TabPanel>
          </TabPanels>
        </Box>
      </Flex>
    </Tabs>
  );
}

export default AdminDashboard;