import React from 'react'
import { 
    Box,
    InputGroup,
    InputLeftAddon,
    Input,
    InputRightAddon,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Button,
    Text,
    Flex,
    Badge,
    Spinner
} from '@chakra-ui/react';
import { useHistory,Link } from 'react-router-dom'
import { onAuthStateChanged } from '@firebase/auth'
import axios from 'axios'
import { BASEURL } from '../../../api';
import { auth } from '../../../api/firebase-config';
const Campaigns = () => {

  const [user,setUser] = React.useState(null)
  const [isFetching,setIsFetching] = React.useState(false)
  const [campaignsList,setCampaingsList] = React.useState([])
  const [noMoreCampaigns,setNoMoreCampaigns] = React.useState(false)


    const CampaignRecord = ({campaignName,campginId,submissionsNumber}) => {
        
        return (<>
        <Link to={`/users-submissions/${campginId}`}>
        <Flex
        bgColor="whiteAlpha.200"
        _hover={{bgColor:'purple.100'}}
        justify={'space-between'}
        px={10}
        py={2}
        my={3}
        borderRadius={3}
        boxShadow='base'
        >
          <Text>{campaignName} - <Text fontSize={'sm'} as='b'>( {submissionsNumber} ) Submissions</Text></Text>
          {/* <Badge colorScheme='green'>SENT</Badge> */}
        </Flex>
        </Link>
        </>)
      }


      const fetchCampaigns = () => {
        setIsFetching(true)
        axios.get(`${BASEURL}/campaigns/all-compaigns`,{
            params : {
                campaignsListLength:campaignsList.length,
                accessToken:user.accessToken
            }
        })
        .then(res=>{
          // console.log(res.data)
            // console.log('campaigns',res.data.results.campaignsListAnalytics)
            if(campaignsList.length > 0 && res.data.results.campaignsListAnalytics == 0) setNoMoreCampaigns(true)
            setCampaingsList([...campaignsList,...res.data.results.campaignsListAnalytics])
        }).then(()=>setIsFetching(false)).catch(err=>{
            console.log('fetch campaigns error',err)
        })
    }

      const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }
    onAuthStateChanged(auth,currentUser=>{
      setUser(currentUser)
  })
  
    React.useEffect(()=>{
      if(user) fetchCampaigns()
  },[user])    
      return (
          <Box>
           {isFetching ? <Loading /> : <>
           {campaignsList.map(campaign=>{
            return <CampaignRecord submissionsNumber={campaign['submissionsNumber']} campaignName={campaign['campaign_name']} campginId={campaign['campaign_id']}/>
           })}
           {campaignsList.length == 0 && <Text>No Campaigns!</Text>}
           {(noMoreCampaigns == false && campaignsList.length != 0) && <Box display={'flex'} justifyContent='center' alignItems={'center'}>
            <Button
            isLoading={isFetching}
            loadingText='Loading'
            colorScheme='purple'
            variant='outline'
            spinnerPlacement='start'
            size='lg'
            onClick={()=>fetchCampaigns()}
            >Load More</Button>
        </Box>}
           </>}
          </Box>
    );
}
 
export default Campaigns;