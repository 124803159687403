import React,{useState} from 'react'
import { Button,Heading, Text, Container} from '@chakra-ui/react'
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Dashboard from './Pages/Dashboard/Dashboard';
import Campaign from './Pages/Campaign/Campaign';
import Pricing from './Pages/Pricing';
import SubmissionsDetails from './Pages/Dashboard/SubmissionsDetails';
import MyPlan from './Pages/MyPlan';
import EditCampaign from './Pages/Dashboard/EditCampaign/EditCampaign';
import Auth from './Pages/Auth/Auth';
import { UserContext } from './api'
// import VerifyEmail from './Pages/TransactionalPages/VerifyEmail';
import AdminDashboard from './Pages/Admin/AdminDashboard';
import EmailActions from './Pages/TransactionalPages/EmailActions';
import Submissions from './Pages/Dashboard/UserList.jsx/Submissions';
import Metadata from './Components/Metadata';

function App() {
  
  const [isAuth,setIsAuth] = useState(localStorage.getItem('isAuth'))

  let globalState = {
    isAuth,setIsAuth
  }

 return <Container maxW={'container.xl'} centerContent>
   <Router>
      <UserContext.Provider value={{...globalState}}>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Text as="h1" fontSize={'lg'}>
              <Metadata title="Home Page"/> 
              Home Page
            </Text>
          </Route>
          {!isAuth && <Route path="/auth">
          <Auth />
          </Route>}
          <Route path="/pricing">
          <Pricing />
          </Route>
          <Route path="/email-actions">
         <EmailActions />
          </Route>
          {isAuth && <Route path="/dashboard" exact>
          <Dashboard />
          </Route>}
          {isAuth &&    <Route path="/dashboard/submissions/:campaignId" exact>
          <SubmissionsDetails />
          </Route>}
          {isAuth &&   <Route path="/dashboard/edit-campaign/:campaignId" exact>
          <EditCampaign />
          </Route>}
          {isAuth && <Route path="/current-plan">
            <MyPlan />
          </Route>}
          
          <Route path="/c/:campaignId">
          <Campaign />
          </Route>
          <Route path='/users-submissions/:campaignId' exact>
            <Submissions />
          </Route>
          {/* Admin Dashboard */}
          {isAuth && <Route path="/admin-dashboard" exact>
          <AdminDashboard />
          </Route>}
          <Route path="*">
            <Heading as="h1" textAlign={"center"}>Page not found.</Heading>
          </Route>
        </Switch>
      </UserContext.Provider>
    </Router>
 </Container>
}
export default App;
