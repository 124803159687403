import React from 'react'
import { Box, Divider,Button,Text,Flex } from '@chakra-ui/react';
import { FiCheck,FiX } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js'
import { BASEURL, STRIPE_PUBLIC_KEY } from '../api';
import axios from 'axios'
import { auth } from './../api/firebase-config'
import { onAuthStateChanged } from '@firebase/auth'
import { useHistory } from 'react-router-dom'
let stripePromise  = loadStripe(STRIPE_PUBLIC_KEY)
 
const Pricing = () => {
    const history = useHistory()
    const [user,setUser] = React.useState(null)
    const [currentPlan,setCurrentPlan] = React.useState('')

    const checkout = async (type) => {
        // alert('checkout')
        const stripe = await stripePromise
        axios.post(`${BASEURL}/plans/checkout`,{
            email:user.email,
            uid:user.uid
        }).then(res=>{
            const { sessionId } = res.data
             stripe.redirectToCheckout({sessionId})
        }).catch(err=>console.log(err))
    }

    const plansFeatures = {
        free : {
            youCan:[
                'Add Campaigns',
                'Send Emails (100/per Day)'
            ],
            youCant:[
                
            ]
        },
        pro : {
            youCan:[
                'Add Campaigns',
                'Submit Emails (10K/per Month)',
                'Use Custom Domain'
            ],
            youCant:[
                
            ]
        }, 
        premium : {
            youCan:[
                'Add Campaign',
                'Remove Campaign',
                'See Analytics',
                'Submit Emails'
            ],
            youCant:[
                
            ]
        }
    }
    const decideFeature = (type) => {
        switch(type) {
            case 'Free':
                return <>
                    {plansFeatures['free'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['free'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
            case 'Pro':
                return <>
                    {plansFeatures['pro'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['pro'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
            case 'Premium':
                return <>
                    {plansFeatures['premium'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['premium'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
        }
    }
    const Feature = ({feature,ability}) => {
        return (<Flex align='center' gap={3} my={1.2}>
            {ability == 1 ? <FiCheck size={20}/> : <FiX size={20}/>}
            <Text>{feature}</Text>
        </Flex>)
    }
    const renderPrice = type => {
        switch(type) {
            case 'free':
                return '0.00'
            break
            case 'pro' :
                return '5.99'
            break
            case 'premium':
                return '8.99'
            break
            default:
                return '0.00'
        }
    }
    const Plan = ({type}) => {
        return <Box
        bgColor={type == 'Pro' ? 'purple.100' : 'transparent'}
        borderWidth={1.5}
        borderColor={'purple.300'}
        py={4}
        px={5}
        borderRadius={7}
        boxShadow='md'
        position='relative'
        // top={{md:(type == 'Pro' && -5)}}
        w={300}
        h={350}
        >
            <Box my={4}
            // bgColor="red.200"
            display='flex'
            flexDirection='column'
            justifyContent={'space-between'}
            alignItems='center'
            >
                <Box>
                <Text fontSize={32}>{type}</Text>
                </Box>
                <Flex align='flex-end'>
                <Text fontSize={17} pos='relative' top={-1}>$</Text>
                <Text fontSize={27}>{renderPrice(type.toLowerCase())}</Text>
                <Text fontSize={17} color='#C2C2C2' pos='relative' top={-1}>/mo</Text>
                </Flex>
            </Box>
            <Divider />
            <Box my={4}>
                {decideFeature(type)}
            </Box>
            <Divider />
            <Flex justify='center' my={4} pos="absolute" bottom={0} left={0} right={0}
            >
                {!user ? <>
                <Button colorScheme={'purple'}
                onClick={()=>history.push('/auth')}
                variant={type != 'Pro' ? 'solid' : 'outline'}
                >
                    <Text>{type == 'Free' ? 'Sign Up ' : 'Select Plan'}</Text>
                </Button>
                </>:<>
                <Button colorScheme={'purple'}
                onClick={()=>{
                    if(currentPlan == 'free' && type != 'Free') checkout('paid')
                    else return
                }}
                variant={type != 'Pro' ? 'solid' : 'outline'}
                >
                    <Text>{type == 'Free' ? 'Current Plan ' : 'Select Plan'}</Text>
                </Button>
                </>}
                
            </Flex>
        </Box>
    }

        onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
        })

        const fetchCurrentPlan = () => {

            axios.get(`${BASEURL}/sellers`,{
                params:{
                    accessToken:user.accessToken
                }
            })
            .then(res=>{
                console.log(res.data)
                if(res.data.plan != 'free') history.replace('/current-plan')
                setCurrentPlan(res.data.plan)
            }).then(err=>{
              console.log(err)
            }).finally(()=>{
            //   setIsFetchingPlan(false)
            })

          }
        
          React.useEffect(()=>{
            if(user) fetchCurrentPlan()
          },[user])

          
    return (
      <>
      {loadStripe && <>
      {(currentPlan == 'free' || currentPlan == '') && <Box pt={10}>
          <Flex
          gap={5}
          direction={{base:'column',md:'row'}}
          mb={10}
          >
            <Plan type='Free' />
            <Plan type='Pro' />
            {/* <Plan type='Premium' /> */}
          </Flex>
        </Box>}
      </>}
      </>
    );
}
 
export default Pricing;