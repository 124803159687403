import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text
} from '@chakra-ui/react';

import {
    signInWithPopup,
    getAdditionalUserInfo,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged
} from '@firebase/auth'

import { authProvider, auth } from '../../api/firebase-config';
import { BASEURL, UserContext } from '../../api';
import { useHistory } from 'react-router-dom';
import { sendPasswordResetEmail } from '@firebase/auth'
import { FcGoogle } from 'react-icons/fc'
import axios from 'axios';
import Metadata from '../../Components/Metadata';

const Auth = () => {
    const history = useHistory()
    const { isAuth, setIsAuth } = useContext(UserContext)
    const [afterManualSignUpMode, setAfterManualSignUpMode] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [isUser, setIsUser] = React.useState(true)
    const [authError, setAuthError] = useState(false)
    const [userData, setUserData] = React.useState({
        email: "",
        password: ""
    })
    const [resetPasswordMode, setResetPasswordMode] = React.useState(false)
    const [resetEmail, setResetEmail] = React.useState('')
    const [resetEmailMessage, setResetEmailMessage] = React.useState('')
    const handleChange = (userInput) => {
        setUserData({ ...userData, ...userInput })
    }
    onAuthStateChanged(auth, currentUser => {
        setUser(currentUser)
    })

    /**
     * SignInWithGoogle method handles both sign in and sign up operations.
     */

    const saveUser = (uid, email, emailVerified) => {

        axios.post(`${BASEURL}/sellers/new_seller`, {
            uid, email, emailVerified
        }).then((res) => {
            console.log(res.data)
        })

    }
    const signInWithGoogle = async () => {
        const res = await signInWithPopup(auth, authProvider)
        try {
            if (res && getAdditionalUserInfo(res).isNewUser) {
                const { uid, email, emailVerified } = res.user
                saveUser(uid, email, emailVerified)
            }
        } catch (error) {
            setAuthError(true)
        }
    }

    const manualSignUp = async () => {
        const { email, password } = userData
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)

            if (res && auth.currentUser) {
                const { uid, email, emailVerified } = res.user
                sendEmailVerification(auth.currentUser)
                saveUser(uid, email, emailVerified)


                setAfterManualSignUpMode(true)
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    const manualSignIn = async () => {
        const { email, password } = userData
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (err) {
            setAuthError(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isUser == true) {
            manualSignIn()
        } else {
            manualSignUp()
        }
    }
    const EmailVerificationMessage = () => {
        return (<Box textAlign={"center"}>
            <Heading as="h3" size="md">Please Verify Your Email</Heading>
            <Text as="p" mt={6}>
                You're almost there! we sent an email to <Text as="span" fontWeight={"bold"}>{auth.currentUser && auth.currentUser.email}</Text>
            </Text>
            <Text my={7}>
                Please click on the link in that email to complete the sign up process.
            </Text>
            <Button colorScheme={'blue'} my={3}
                onClick={() => sendEmailVerification(auth.currentUser)}
            >Resend Email</Button>
            <Text>Please Wait between 1-2 mins to resend a new activation link</Text>
        </Box>)
    }

    const handleResetSubmit = (e) => {
        e.preventDefault()

        sendPasswordResetEmail(auth, resetEmail)
            .then(() => {
                setResetEmail('')
                setResetEmailMessage('A reset email has been successfully sent to this email if exists')
            })
    }

    React.useEffect(() => {
        if (user) {
            if (user.emailVerified) {
                setIsAuth(true)
                localStorage.setItem('isAuth', true)
                history.replace('/')
            }
        }
    }, [user])
    return <Flex
        align='center'
        justify={"center"}
        minH={300}
        mt={10}
    >
        <Metadata title='Auth Page' />
        <Box w={{ base: '100%', md: 500 }} borderWidth={3} borderRadius={3} shadow="md">
            <Flex
                direction={"column"}
                gap={5}
                py={10}
                px={5}
            >
                {(user && !user?.emailVerified) && <EmailVerificationMessage />}
                {!user && (resetPasswordMode == false && afterManualSignUpMode == false) && <>
                    <Box textAlign={"center"}>
                        <Heading as="h1">{isUser == true ? 'Sign in' : 'Sign up'}</Heading>
                        {authError && <Text as="p" color={"crimson"}>You have entered an invalid username or password</Text>}
                    </Box>
                    <Box>
                        <form autoComplete='off' onSubmit={handleSubmit}>
                            <FormControl>
                                <FormLabel>Email Address</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="email"
                                    value={userData.email}
                                    onChange={(e) => handleChange({ email: e.target.value })}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    value={userData.password}
                                    type="password" onChange={(e) => handleChange({ password: e.target.value })}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <Box
                                display={"flex"}
                                justifyContent={"right"}
                                my={3}
                            >
                                {isUser && <Button color="teal.400" variant={"link"} onClick={() => setResetPasswordMode(true)}>Forgot Password?</Button>}
                            </Box>
                            <Button w="full" size="lg" colorScheme={"purple"} variant={"solid"} type="submit">{isUser == true ? 'Sign In' : 'Sign Up'}</Button>
                        </form>
                    </Box>
                    <Button
                        colorScheme={"purple"}
                        size={'lg'}
                        onClick={signInWithGoogle}
                        leftIcon={<FcGoogle />}
                        variant={"outline"}
                    >Sign In With Google</Button>
                    <Box>
                        <Text textAlign={"center"}>{isUser ? "Don't have an account?" : "already have an account?"} <Button variant={'link'} onClick={() => setIsUser(prev => !prev)}>{isUser == true ? "Sign Up" : "Sign In"}</Button></Text>
                    </Box>
                </>}
                {(!user && resetPasswordMode) && <>
                    <Box textAlign={"center"}>
                        <Heading as="h1">Reset Password</Heading>
                        <Text as="p" color={"green.300"} fontWeight='bold' fontSize="lg">{resetEmailMessage}</Text>
                    </Box>
                    <Box>
                        <form autoComplete='off' onSubmit={handleResetSubmit}>
                            <FormControl>
                                <FormLabel>Your E-mail</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="email" onChange={(e) => setResetEmail(e.target.value)}
                                    value={resetEmail}
                                    enterKeyHint='done'
                                    onFocus={() => setResetEmailMessage('')}
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <Box
                                display={"flex"}
                                justifyContent={"right"}
                                my={3}
                            >
                                <Button color="teal.400" variant={"link"} onClick={() => setResetPasswordMode(false)}>Cancel</Button>
                            </Box>
                            <Button w="full" size="lg" colorScheme={"purple"} variant={"solid"} type="submit">Reset</Button>
                        </form>
                    </Box>
                </>}
            </Flex>
        </Box>
    </Flex>;
}

export default Auth;